/* Viva la Vida */

.top-img{
  width: 40%;
  align-self: center;
  margin-top:0;
  /* border-width: .25px;
  border-style: solid; */
  
}
.boxLogo {
  display: inline-block;
  width: 80%;
  text-align: center;
}

/* .navbar-brand{
  width:50%; 
 /* display:inline-block;
  border-width: .25px;
  border-style: solid;
  padding-top:0px;
} */

.bg-body-tertiary{
  background-color: #FFF !important;
}
.navbar-nav{
margin-top:30px;
}
.navbar-nav li a{
margin-left:20px
}

.navbar-nav .bi{
  padding-top: 10px;
}

.bi{
  /* padding-top: 10px; */
  color:#016A70
}

.group_li{
  
}
ul.list_props{
  list-style-type: none;

}
ul.list_props li{
 margin-left: 10px;
}

.conten_box{
  text-align: left;
  padding-left:10px;
  padding-right: 10px;
  padding-bottom: 20px;
}
.conten_box p{
color:#7B7A7A;
}

.conten_box li{
  color:#7B7A7A;
  }



.footer_green{
  background-color: #016A70;
  margin-bottom: -20px;
  padding: 20px;
}
p i{
  margin-right:15px
}
.footer_green p{
  align-self: center;
  color:#9CD8DD
}

@font-face {
  font-family: 'HelveticaNeueLTCom57Cn';
  src: url('../fonts/HelveticaNeueLTCom-Cn.eot');
  src: url('../fonts/HelveticaNeueLTCom-Cn.eot?#iefix') format('embedded-opentype'),
      url('../fonts/HelveticaNeueLTCom-Cn.woff2') format('woff2'),
      url('../fonts/HelveticaNeueLTCom-Cn.woff') format('woff'),
      url('../fonts/HelveticaNeueLTCom-Cn.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

h1{
  font-family: 'HelveticaNeueLTCom57Cn';
  font-size: 3em;
  text-transform: uppercase;
  margin-top:20px;
  font-weight: normal;
  font-style: normal;
  border-bottom: #016A70;
  border-bottom-width: 1px;
  border-bottom-style: solid;

}

h2{
  font-family: 'HelveticaNeueLTCom57Cn';
  font-size: 1.7em;
  text-transform: uppercase;
  margin-top:10px;
  font-weight: normal;
  font-style: normal;
  margin-bottom: 0px;

}
.row_proy{
  border-bottom: #9CD8DD;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.row_proy{
  border-bottom: #9CD8DD;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.row_proy.no_border{
  border-bottom-width:0;
}

.row_proy p{
margin-bottom: 5px;
overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}

.conten_box a{
  text-decoration: none;
}

.conten_box a h2{
 color:#7B7A7A
}

.skeleton{
  animation: skeleton-loading 1s linear infinite alternate
}
@keyframes skeleton-loading {
  0% {
      background-color: hsl(200, 20%, 70%)
  }
  100% {
      background-color: hsl(200, 20%, 95%)
  }
}




.nav-link{
  font-family: 'HelveticaNeueLTCom57Cn';
  color:#016A70
}
.navbar-toggler{
  border:none;
  padding: 0;
}
.navbar-toggler:focus{
  border:none;
  box-shadow: none;
}
li.nav-item, .item-nav{
  /* border-bottom: #9CD8DD;
  border-bottom-width: 1px;
  border-bottom-style: solid; */
}
.boxLogo{
  text-align: center;
}
.only_st{
  color:#FFF
}
.flecha{
  font-size:1.3em;
}
div.rowToMenu{
  display: flex;
}

.rowToMenu{
  flex-direction: row  !important;;
  justify-items: stretch;
  border-bottom: #9CD8DD;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}